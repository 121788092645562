@import "../../utils/colors.scss";

.gameCardContainer {
  background-color: white;
}

.imgStyle {
  min-height: 222px;
  min-width: 222px;
  max-height: 436px;
  max-width: 436px;
  object-fit: contain;
  width: 100%;
}

.title {
  font-family: InterMedium;
  color: $DARKGREY;
  font-size: 18px;
}

.btnStyle2 {
  background-color: white;
  color: $DARKGREY;
  height: 52px;
  border: 1px solid $DARKGREY;
  font-family: PoppinsRegular;
  text-transform: uppercase;
  font-size: 12px;
  width: 70%;
  &:hover {
    background-color: $DARKGREY;
    color: white;
  }
}

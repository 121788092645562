@import "shared/utils/colors.scss";

.cover-preview {
  width: 100%;
}
.cover-preview-img {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  max-height: 800px;
  margin: auto;
  object-fit: contain;
}

.cover-content {
  position: absolute;
  bottom: 95px;
  left: 35px;
}
.slick-next {
  display: none !important;
}
.slick-prev {
  display: none !important;
}

.arrowsMainContainer {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  height: 84px;
  width: 100%;
}

.arrowsSubContainer {
  margin: auto;
  max-width: 1920px !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dotsMainContainer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: white;
}

.highlighted-dot {
  background-color: $GOLD;
}

.leftArrow {
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.icon-arrow {
  height: 22px;
  width: 22px;
}

.rightArrow {
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (min-width: 300px) and (max-width: 470px) {
  .arrowsMainContainer {
    margin-top: -40px;
  }
}

@media screen and (min-width: 470px) and (max-width: 500px) {
  .arrowsMainContainer {
    margin-top: -40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 576px) {
  .arrowsMainContainer {
    margin-top: -40px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .arrowsMainContainer {
    margin-top: -35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 850px) {
  .arrowsMainContainer {
    margin-top: -35px;
  }
}
@media screen and (min-width: 850px) and (max-width: 992px) {
  .arrowsMainContainer {
    margin-top: -35px;
  }
}

@media screen and (min-width: 1440px) {
  .arrowsMainContainer {
    margin-top: -30px;
  }
}

@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.breadCrump {
  font-size: 14px;
  font-family: InterMedium;
  color: $DARKGREY;
}

.title {
  font-size: 35px;
  font-family: InterSemiBold;
  color: $DARKGREY;
}

.minHeight {
  min-height: 200px;
}

.singlePageNumber {
  padding: 10px 15px;
  color: $GOLD;
  &:hover {
    cursor: pointer;
    color: $DARKGREY;
  }
}
.animationContainer {
  height: 300px !important;
  width: 300px !important;
}

.singlePageActive {
  color: $DARKGREY;
  background-color: $GRAYTRANSPARENT;
  &:hover {
    cursor: default;
  }
}

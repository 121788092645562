@import "../../utils/colors.scss";
.customBtnContainer {
  background-color: $DARKGREY;
  color: white;
  height: 40px;
  border: 1px solid $DARKGREY;
  font-family: PoppinsRegular;
  text-transform: uppercase;
  font-size: 12px;
  width: 70%;
  &:hover {
    background-color: white;
    color: $DARKGREY;
  }
}

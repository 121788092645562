@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

figure {
  max-height: 915px;
  max-width: 915px;
  min-height: 350px;
  min-width: 350px;
  width: 100%;
  background-repeat: no-repeat;
}

.imgLoader {
  max-height: 915px;
  max-width: 915px;
  min-height: 350px;
  min-width: 350px;
  width: 100%;
}

figure:hover img {
  opacity: 0;
}

.imgZ {
  display: block;
  width: 100%;
  pointer-events: none;
}

.breadCrump {
  font-size: 14px;
  font-family: InterMedium;
  color: $DARKGREY;
}

.breadCrumpLoader {
  height: 14px;
  width: 300px;
}

.category {
  font-size: 15px;
  font-family: PoppinsRegular;
  color: $DARKGREY;
}

.categoryTitle {
  font-size: 16px;
  font-family: PoppinsSemiBold;
  color: $DARKGREY;
}

.categoryLoader {
  height: 15px;
  width: 200px;
}
.title {
  font-size: 40px;
  font-family: InterBold;
  color: $DARKGREY;
}

.titleLoader {
  height: 40px;
  width: 200px;
}
.numField {
  height: 52px;
  width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 15px 20px;
  outline: 0;
  border: 1px solid grey;
}

.numFieldLoader {
  height: 52px;
  width: 58px;
}

.btnContainerLoader {
  height: 52px;
  width: 190px;
}

.btnContainer {
  background-color: $DARKGREY;
  color: white;
  height: 52px;
  border: 1px solid $DARKGREY;
  font-family: PoppinsRegular;
  text-transform: uppercase;
  font-size: 12px;
  width: 190px;
  &:hover {
    background-color: white;
    color: $DARKGREY;
  }
}

.sugestLabel {
  font-size: 35px;
  font-family: InterMedium;
  color: $DARKGREY;
}

.minHeight {
  min-height: 200px;
}

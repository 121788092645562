@import "../../utils/colors.scss";

.inputLabel {
  font-size: 14px;
  font-family: InterRegular;
  margin-bottom: 5px;
  color: $GREY;
  text-align: left;
}

.asterik {
  color: red;
}

.inputContainer {
  background-color: #f8f8f9;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.inputStyle {
  width: 100%;
  height: 100%;
  background-color: #f8f8f9;
  border: 0px;
  outline: 0;
  font-family: InterRegular;
  font-size: 14px;
  color: $DARKGREY !important;
}

.inputStyle::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: InterRegular;
  color: $GREY;
  opacity: 1; /* Firefox */
  font-size: 14px;
}

.inputStyle:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: InterRegular;
  color: $GREY;
  font-size: 14px;
}

.inputStyle::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: InterRegular;
  color: $GREY;
  font-size: 14px;
}

.error {
  color: $ERROR;
  font-size: 12px;
  text-align: left;
  position: absolute;
  top: 78px;
  z-index: 1;
  /* margin: 10px 0; */
}

input[type="email"]:disabled {
  color: $GREY !important;
}

@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.headerContainer {
  min-height: 81px;
  background-color: $DARKGREY;
  position: relative;
}

.logoIcon {
  height: 70px;
  width: 100%;
}

.navlabel {
  font-size: 16px;
  color: white;
  font-family: InterMedium;
  border-bottom: 1px solid $DARKGREY;
  transition: 0.5s color;
  transition: 0.5s border;
  &:hover {
    color: $GOLD;
    border-bottom: 1px solid $GOLD;
    cursor: pointer;
  }
}

.activeNav {
  color: $GOLD;
  border-bottom: 1px solid $GOLD;
}

.cartIcon {
  height: 24px;
  width: 24px;
}

.countCont {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  color: white;
  background-color: $GOLD;
  position: absolute;
  top: -15px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countLabel {
  font-size: 12px;
  color: white;
  font-family: InterMedium;
}

.dropDownContainer {
  position: absolute;
  bottom: -185px;
  z-index: 9;
  left: 0;
  width: 180px;
  height: fit-content;
  background-color: $GOLD;
}

.categCont {
  position: relative;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background-color: $DARKGREY;
    cursor: pointer;
  }
}

.categTitle {
  color: white;
  font-size: 12px;
  font-family: PoppinsMedium;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
}

.subCateg1 {
  position: absolute;
  top: 0;
  right: 180px;
  height: fit-content;
  background-color: $GOLD;
  width: 250px;
}

@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.imgStyle {
  height: 72px;
  width: 72px;
}
.shopingCartTop {
  border-bottom: 2px solid lightgray;
}
.title {
  font-family: InterMedium;
  color: $DARKGREY;
  font-size: 16px;
}

@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.backDropContainer {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100;
  padding: 0px !important;
  transition: visibility 0.2s;
  overflow: hidden;
}

.mainContainer {
  background-color: $DARKGREY;
  max-width: 300px;
  height: 100%;
  padding: 0px !important;
  overflow-y: scroll;
  transition: transform 0.3s;
  position: absolute;
  right: 0;
  width: 100%;
  overflow-x: hidden;
}

.hidden {
  transform: translateX(300px);
}

.shown {
  transform: translateX(0px);
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.navlabel {
  font-size: 16px;
  color: white;
  font-family: InterMedium;
  border-bottom: 1px solid $DARKGREY;
  transition: 0.5s color;
  transition: 0.5s border;
  text-transform: uppercase;
  &:hover {
    color: $GOLD;
    border-bottom: 1px solid $GOLD;
    cursor: pointer;
  }
}

.activeNav {
  color: $GOLD;
  border-bottom: 1px solid $GOLD;
}

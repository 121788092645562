@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.productsContainer {
  border: 1px solid lightgrey;
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.btn {
  background-color: lightgray;
  color: $DARKGREY;
  height: 35px;
  font-family: PoppinsSemiBold;
  text-transform: capitalize;
  font-size: 12px;
  width: 145px;
  border: 0;
}

.emptyLabelContainer {
  border-top: 3px solid $GOLD;
  width: 100%;
  text-align: left;
}

.emptyLabel {
  font-size: 16px;
  font-family: InterRegular;
  color: $DARKGREY;
}

.titleLabel {
  font-size: 14px;
  font-family: InterSemiBold;
  color: $DARKGREY;
  text-align: left;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.updateBtn {
  background-color: white;
  color: $DARKGREY;
  border: 1px solid $GOLD;
  height: 45px;
  font-family: PoppinsSemiBold;
  text-transform: uppercase;
  font-size: 12px;
  width: 155px;
}

.proccedBtn {
  background-color: $GOLD;
  color: white;
  border: 0px;
  height: 45px;
  font-family: PoppinsSemiBold;
  font-size: 14px;
  width: 100%;
  &:hover {
    background-color: $DARKGREY;
  }
}

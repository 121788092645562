@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.aboutContainer {
  background: url("../../assets/images/about-us.jpeg") no-repeat center center;
  background-size: cover;
  max-height: 600px;
  max-width: 1920px;
  height: 100%;
  width: 100%;
  display: flex;
}
.title {
  font-size: 80px;
  font-family: InterBold;
  color: $GOLD;
  text-transform: uppercase;
  text-align: left;
}

.contenttitle {
  font-size: 30px;
  font-family: PoppinsMedium;
  color: black;
  text-align: left;
}

.contentSubtitle {
  font-size: 16px;
  font-family: PoppinsSemiBold;
  color: black;
  text-align: left;
}

.contentText {
  font-size: 16px;
  font-family: sans-serif;
  color: black;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .aboutContainer {
    max-height: 200px;
    max-width: 1920px;
  }
  .title {
    font-size: 40px;
    text-align: center;
  }
}
@media screen and (min-width: 576px) {
  .aboutContainer {
    max-height: 200px;
    max-width: 1920px;
  }
  .title {
    font-size: 40px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .aboutContainer {
    max-height: 400px;
    max-width: 1920px;
  }
  .title {
    font-size: 60px;
    text-align: left;
  }
}
@media screen and (min-width: 992px) {
  .aboutContainer {
    max-height: 600px;
    max-width: 1920px;
  }
  .title {
    font-size: 80px;
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .aboutContainer {
    max-height: 600px;
    max-width: 1920px;
  }
  .title {
    font-size: 80px;
    text-align: left;
  }
}

@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.titleLabel {
  font-size: 14px;
  font-family: InterSemiBold;
  color: $GOLD;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.numField {
  height: 28px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 15px 20px;
  outline: 0;
  border: 1px solid grey;
}

.imgStyle {
  height: 55px;
  width: 55px;
}

.h100 {
  height: 100%;
}

.brBtm {
  border-bottom: 1px solid lightgrey;
}

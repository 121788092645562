@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.footerContainer {
  background-color: $DARKGREY;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoIcon {
  height: 150px;
  width: 286px;
}

.aboutLabel {
  font-size: 14px;
  font-family: PoppinsMedium;
  color: #dadada;
  text-align: left;
}

.listTitle {
  font-size: 18px;
  font-family: PoppinsMedium;
  color: #dadada;
  text-align: left;
  text-transform: uppercase;
}

.listItem {
  font-size: 14px;
  font-family: PoppinsRegular;
  color: #dadada;
  text-align: left;
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: inherit;
}

.listIcon {
  height: 18px;
  width: 18px;
  fill: $GOLD;
  stroke-width: 0;
  margin-right: 10px;
}

.listIcon polyline {
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: butt;
}
.listIcon circle {
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: butt;
}

.listItem:hover {
  cursor: pointer;
}

.listItem:hover .listIcon {
  fill: white;
}

.listItem:hover .listIcon polyline {
  stroke: $GOLD;
  stroke-width: 2px;
  stroke-linecap: butt;
}

.listItem:hover .listIcon circle {
  stroke: $GOLD;
  stroke-width: 2px;
  stroke-linecap: butt;
}

.socialIcon {
  height: 30px;
  width: 30px;
}

.footerbtm {
  background-color: $GOLD;
}

.footerBtmLabel {
  font-size: 13px;
  font-family: PoppinsMedium;
  color: white;
  text-align: left;
}

@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.backDropContainer {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100;
  padding: 0px !important;
  transition: visibility 0.2s;
  overflow: hidden;
}

.mainContainer {
  background-color: white;
  max-width: 300px;
  height: 100%;
  padding: 0px !important;
  overflow-y: scroll;
  transition: transform 0.3s;
  position: absolute;
  right: 0;
  width: 100%;
  overflow-x: hidden;
}

.hidden {
  // margin-left: -300px;
  transform: translateX(300px);
}

.shown {
  // margin-left: 0px;
  transform: translateX(0px);
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.title {
  font-size: 24px;
  font-family: PoppinsSemiBold;
  color: black;
  text-transform: uppercase;
}

.btn1 {
  background-color: white;
  color: $DARKGREY;
  height: 40px;
  border: 1px solid $GREY;
  font-family: PoppinsRegular;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  &:hover {
    background-color: $GREY;
    color: white;
  }
}

.btn2 {
  background-color: $GREY;
  color: white;
  height: 40px;
  border: 1px solid $GREY;
  font-family: PoppinsRegular;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  &:hover {
    background-color: white;
    color: $DARKGREY;
  }
}

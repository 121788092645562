@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.titleContainer {
  background-color: $DARKGREY;
  height: 65px;
  margin-top: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 24px;
  text-transform: uppercase;
  font-family: InterSemiBold;
  color: white;
}

.imgStyle {
  min-width: 240px;
  min-height: 240px;
  max-width: 446px;
  max-height: 446px;
  object-fit: contain;
  width: 100%;
  transition-duration: 1.1s;
  &:hover {
    cursor: pointer;
    transform: scale(0.9);
    filter: brightness(100%) contrast(100%) saturate(100%) blur(1.2px)
      hue-rotate(0deg);
  }
}

.topLevelContainer {
  height: 100vh;
  display: flex;
  overflow-x: hidden;
}
.mainContainer {
  background-color: white;
  overflow-y: visible;
  width: 100vw;
  overflow-x: hidden;
}

.sideNavContainer {
  background-color: white;
  border-right: 1px solid #e4e4e4;
  overflow-y: scroll;
  height: 100vh;
}

.sideNavFull {
  min-width: 256px;
  transition: min-width 0.4s;
  -webkit-transition: min-width 0.4s;
  -moz-transition: min-width 0.4s;
}

.sideNavHalf {
  min-width: 96px;
  transition: width 0.4s;
}

.sideNavContainer::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.mainContainer::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.sideNavContainer::-webkit-scrollbar-thumb {
  background-color: #ff754c;
  border-radius: 100px;
  visibility: hidden;
}

.sideNavContainer::-webkit-scrollbar-track {
  /* background-color: #818b99; */
  border: 1px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  background-color: #f0f3f6;
  visibility: hidden;
  /* border-radius: 100px; */
}

.showSideNavContainer::-webkit-scrollbar-thumb {
  visibility: visible !important;
}

.showSideNavContainer::-webkit-scrollbar-track {
  visibility: visible !important;
}

.iconContainer {
  position: absolute;
  z-index: 999;
  bottom: 20px;
  right: 20px;
}

.icon {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .showSideNavContainer::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .sideNavContainer {
    display: none;
  }
}
@media screen and (min-width: 576px) {
  .sideNavFull {
    min-width: 96px;
  }
}
@media screen and (min-width: 768px) {
  .sideNavFull {
    min-width: 96px;
  }
}
@media screen and (min-width: 992px) {
  .sideNavFull {
    min-width: 96px;
  }
}

@media screen and (min-width: 1200px) {
  .sideNavFull {
    min-width: 256px;
  }
}

@media screen and (min-width: 1400px) {
  .sideNavFull {
    min-width: 256px;
  }
}

.customContainer {
  margin: auto;
}
.w10 {
  width: 10% !important;
}
.w15 {
  width: 15% !important;
}
.w20 {
  width: 20% !important;
}
.w30 {
  width: 30% !important;
}
.w80 {
  width: 80% !important;
}
.w85 {
  width: 85% !important;
}
.w90 {
  width: 90% !important;
}

@media screen and (max-width: 576px) {
  .customContainer {
    max-width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .customContainer {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .customContainer {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .customContainer {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .customContainer {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .customContainer {
    max-width: 1320px;
  }
}

@media screen and (min-width: 1600px) {
  .customContainer {
    max-width: 1500px;
  }
}

@media screen and (min-width: 1800px) {
  .customContainer {
    max-width: 1688px;
  }
}

@media screen and (min-width: 2000px) {
  .customContainer {
    max-width: 1875px;
  }
}

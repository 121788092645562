@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.productsContainer {
  border: 1px solid lightgrey;
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.titleLabel {
  font-size: 14px;
  font-family: InterSemiBold;
  color: $DARKGREY;
  text-align: left;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productLabel {
  font-size: 14px;
  font-family: InterSemiBold;
  color: $GREY;
  text-align: left;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reqLabel {
  font-size: 14px;
  font-family: InterSemiBold;
  color: $GREY;
  text-align: left;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptyLabelContainer {
  border-top: 3px solid $GOLD;
  width: 100%;
  text-align: left;
}

.emptyLabel {
  font-size: 16px;
  font-family: InterRegular;
  color: $DARKGREY;
}

.btn {
  background-color: lightgray;
  color: $DARKGREY;
  height: 35px;
  font-family: PoppinsSemiBold;
  text-transform: capitalize;
  font-size: 12px;
  width: 145px;
  border: 0;
}

.pTitle {
  font-size: 14px;
  font-family: InterMedium;
  color: $DARKGREY;
  text-align: left;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pSubTitle {
  font-size: 12px;
  font-family: InterMedium;
  color: $DARKGREY;
  text-align: left;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prooceedBtn {
  background-color: $DARKGREY;
  color: white;
  height: 40px;
  border: 1px solid $DARKGREY;
  font-family: PoppinsRegular;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  &:hover {
    background-color: white;
    color: $DARKGREY;
  }
}

.infoLabel {
  font-size: 12px;
  font-family: InterMedium;
  color: $GREY;
}

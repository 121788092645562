@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";
.title {
  font-size: 80px;
  font-family: InterBold;
  color: $GOLD;
}

.subtitle {
  font-size: 30px;
  font-family: PoppinsMedium;
}

.listItem {
  font-size: 14px;
  font-family: PoppinsRegular;
  color: black;
  text-align: left;
  display: flex;
  align-items: center;
}

.listIcon {
  height: 18px;
  width: 18px;
  fill: $GOLD;
  stroke-width: 0;
  margin-right: 10px;
}

.listIcon polyline {
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: butt;
}
.listIcon circle {
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: butt;
}

.listItem:hover {
  cursor: pointer;
}

.listItem:hover .listIcon {
  fill: black;
}

.listItem:hover .listIcon polyline {
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: butt;
}

.listItem:hover .listIcon circle {
  stroke: white;
  stroke-width: 2px;
  stroke-linecap: butt;
}

.inputContainer {
  border: 1px solid grey;
  outline-color: black;
  min-height: 40px;
  min-width: 60%;
  padding: 10px;
}

.btnStyle {
  height: 50px;
  width: 150px;
  background-color: black;
  color: white;
  outline: 0;
  border: none;
  font-family: InterRegular;
  text-transform: uppercase;
  font-size: 12px;
  transition: 0.4s background-color;

  &:hover {
    background-color: $GOLD;
  }
}
